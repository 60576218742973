import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Fragment } from 'react';

const Result = ({ yes, title, subtitle, result, success, percent }) => {
  return (
    <div
      className={twMerge(
        'flex justify-between p-2 gap-2 rounded group break-inside-avoid mb-2',
        success ? 'bg-brand-green-1' : 'bg-brand-red-1'
      )}
    >
      <div className="flex flex-col">
        <div className="flex gap-1">
          {typeof yes != 'undefined' && (
            <span
              className={twMerge(
                'text-xs font-bold capitalize',
                yes ? 'text-brand-green-4' : 'text-brand-red-4'
              )}
            >
              {yes ? 'Yes' : 'No'}
            </span>
          )}
          <p className="text-xs font-bold text-brand-gray-5 capitalize">
            {typeof yes != 'undefined' ? title.slice(yes ? 4 : 3) : title}
          </p>
        </div>
        {subtitle && <p className="text-xs font-light text-brand-gray-5">{subtitle}</p>}
      </div>
      <p
        className={twMerge(
          'text-xs font-bold capitalize whitespace-nowrap text-right',
          success ? 'text-brand-green-4' : 'text-brand-red-4'
        )}
      >
        {result}
        {percent && (
          <Fragment>
            <br /> {percent}
          </Fragment>
        )}
      </p>
    </div>
  );
};

export default Result;
