import React, { useMemo, useState, Fragment } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { twMerge } from 'tailwind-merge';
import ReactMarkdown from 'react-markdown';
import ReactPlayer from 'react-player/youtube';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Result from '../components/election/result';
import Table from '../components/layout/table';
import Question from '../components/pulse/question';
import BarChart from '../components/pulse/barChart';
import List from '../components/list';
import { SuperButton } from '../components/layout/buttons';
import InnerNav from '../components/layout/innerNav';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';
import { bluePalette } from '../utils/pulsePalette';
import arrowDecorator from '../images/icons/icon-arrows-decorator.svg';
import ShowIfPageScrolled from '../components/showIfPageScrolled';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const maxTextWidth = 'max-w-3xl';
const SectionTitle = ({ className = '', title }) => (
  <div className={twMerge(`${maxTextWidth} mx-auto py-6 lg:pt-12`, className)}>
    <h2 className="text-2xl font-black text-brand-blue-5 mb-1.5 lg:text-4xl">{title}</h2>
    <img src={arrowDecorator} width={41} height={13} alt="Arrow Decorator" />
  </div>
);
const H3 = ({ className, children }) => (
  <h3
    className={twMerge(
      `${maxTextWidth} mx-auto text-xl font-bold text-brand-blue-5 mb-3 lg:text-2xl lg:font-black`,
      className
    )}
  >
    {children}
  </h3>
);
const H4 = ({ className, children }) => (
  <h4
    className={twMerge(
      `${maxTextWidth} mx-auto text-lg font-semibold text-brand-blue-5 mb-3 lg:text-xl lg:font-semibold`,
      className
    )}
  >
    {children}
  </h4>
);
const DividerPattern = () => (
  <div className="h-20 w-full bg-[url('../images/misc/textured-bg-blue.svg')] [background-size:70%]" />
);
const SpentNumber = ({ unit, value, subtitle, normalCase }) => (
  <div className="flex flex-col">
    <p
      className={twMerge(
        'text-3xl lg:text-5xl font-black text-brand-blue-4',
        normalCase ? '' : 'uppercase'
      )}
    >
      {unit && <sup className="text-xl lg:text-3xl normal-case relative -top-3">{unit}</sup>}
      {value}
    </p>
    <p className="text-base text-brand-blue-5">{subtitle}</p>
  </div>
);
const CTA = () => (
  <div className="relative w-full max-w-2xl mx-auto bg-brand-blue-1 flex flex-col gap-5 p-5 overflow-clip isolate lg:justify-center lg:items-center lg:p-10">
    <StaticImage
      className="!absolute object-contain z-0 -bottom-5 right-0 lg:-bottom-10"
      src="../images/core/sf-illustration-white.svg"
      alt="Line art illustration of San Francisco"
    />
    <p className="relative text-sm text-brand-blue-5 font-bold lg:text-xl">
      For press inquiries, get in touch at contact@growsf.org
    </p>
    <SuperButton
      className="relative max-w-max"
      color="blue"
      size="sm"
      to="mailto:contact@growsf.org"
    >
      Contact us
    </SuperButton>
  </div>
);
const P = ({ children, className }) => (
  <p
    className={twMerge(
      `${maxTextWidth} text-base text-gray-700 leading-relaxed pt-2 mx-auto pb-3 lg:pb-6`,
      className
    )}
  >
    {children}
  </p>
);
const A = ({ children, href }) => (
  <a href={href} className="text-brand-blue-4 hover:text-blue-800 underline">
    {children}
  </a>
);
const Majority = () => {
  const Section = ({ title, children }) => (
    <div className="w-auto break-inside-avoid">
      <h3 className="w-full text-xl font-bold text-brand-blue-5 mb-3 lg:text-2xl lg:font-black">
        {title}
      </h3>
      <div className="w-full">{children}</div>
    </div>
  );
  const InnerSection = ({ children }) => (
    <div className="w-full columns-2 md:columns-1 gap-6">{children}</div>
  );
  return (
    <section data-scroll="majority" className="mx-auto ">
      <div className="max-w-7xl mx-auto px-5">
        <SectionTitle className="pb-0" title="A New Majority" />
        <P>
          We're excited to announce that San Francisco has a trifecta of commonsense leaders: Mayor
          Lurie, a 6-to-5 majority on the Board of Supervisors, and a 5-to-2 majority on the Board
          of Education. From housing, to public safety, to great public schools, these leaders will
          work together to make San Francisco a better place for all of us.
        </P>
        <P>
          When we started GrowSF four years ago, we had a Mayor aligned with GrowSF's positions, but
          Mayor Breed was held back by an oppositional board, a DA who didn't prosecute crime, and a
          school board more focused on renaming schools than on student outcomes. Today's majority
          is the culmination of four years of hard work and dedication by our team, our supporters,
          volunteers, and most importantly voters.
        </P>
        <div className="w-full columns-1 md:columns-3 gap-6">
          {/* SF Candidates */}
          <Section title="Mayor">
            <P className="pb-6">
              Reformer Democrat <b>Daniel Lurie won</b>, and <b>Aaron Peskin lost decisively</b>.
            </P>
            <InnerSection>
              <Result title="Daniel Lurie" result="Elected 2024" success={true} />
            </InnerSection>
          </Section>
          <Section title="Board of Supervisors">
            <P>We've obtained a 6-to-5 majority of commonsense leaders.</P>
            <InnerSection>
              <Result
                title="Catherine Stefani"
                subtitle="Supervisor, District 2"
                result="Elected 2022"
                success={true}
              />
              <Result
                title="Danny Sauter"
                subtitle="Supervisor, District 3"
                result="Elected 2024"
                success={true}
              />
              <Result
                title="Joel Engardio"
                subtitle="Supervisor, District 4"
                result="Elected 2022"
                success={true}
              />
              <Result
                title="Bilal Mahmood"
                subtitle="Supervisor, District 5"
                result="Elected 2024"
                success={true}
              />
              <Result
                title="Matt Dorsey"
                subtitle="Supervisor, District 6"
                result="Elected 2022"
                success={true}
              />
              <Result
                title="Rafael Mandelman"
                subtitle="Supervisor, District 8"
                result="Elected 2022"
                success={true}
              />
            </InnerSection>
          </Section>
          <Section title="Board of Education">
            <P>We've obtained a 5-to-2 majority of commonsense leaders.</P>
            <InnerSection>
              <Result title="Jaime Huling" result="Elected 2024" success={true} />
              <Result title="Parag Gupta" result="Elected 2024" success={true} />
              <Result title="Supryia Ray" result="Elected 2024" success={true} />
              <Result title="Phil Kim" result="Appointed 2024" success={true} />
              <Result title="Lisa Weissman-Ward" result="Elected 2022" success={true} />
            </InnerSection>
          </Section>
        </div>
      </div>
    </section>
  );
};
const HowWeDidIt = ({ data }) => {
  const VGPrintCreative = getImage(data.VGPrintCreative.childImageSharp);
  const PrintCreative = getImage(data.PrintCreative.childImageSharp);
  const YTSmall = ({ url }) => (
    <div className="w-[350px] h-[196px]">
      <ReactPlayer
        url={url}
        config={{
          youtube: {
            playerVars: { controls: 1 },
          },
        }}
        className="rounded-md"
        width="100%"
        height="100%"
      />
    </div>
  );
  return (
    <section data-scroll="how-we-did-it">
      <div className="max-w-7xl mx-auto px-5">
        <SectionTitle className="pb-0" title="How We Did It" />
        <P>
          We run a rigorous process to identify the best candidates: each candidate for every office
          gets a long questionnaire that asks the important questions. We look for candidates who
          prioritize good government, building more housing, creating great public schools, ensuring
          public safety, and fostering growth. And they must share our San Francisco values.
        </P>
        <P>
          A core belief of GrowSF is that voters just want high quality information on what they're
          voting for. Instead of relying on expensive political consultants, we do all our research
          in house. In total, we produced hundreds of pages of candidate interviews, financial
          analyses, and no-frills policy analysis to help voters make informed decisions.
        </P>
        <P>
          We got information in front of as many people as possible, both organically and through
          ads, across all six supervisorial districts. While we did not win every district, we
          succeeded in delivering the most well-researched voter guide in the City.
        </P>
        <div className="w-full flex flex-col gap-8 justify-center lg:items-center lg:gap-16 md:flex-row">
          <GatsbyImage
            className="object-scale-down w-full h-full max-w-2xl"
            image={VGPrintCreative}
            alt="Our web and print voter guides"
          />
          {/* Metrics*/}
          <div className="flex flex-col gap-6 lg:gap-8 min-w-[300px] lg:min-w-[350px]">
            <SpentNumber value="500k+" subtitle="voters reached through direct mail" />
            <SpentNumber value="181k" subtitle="unique visitors to our online voter guide" />
            <SpentNumber
              value="14 variants"
              subtitle="English and Chinese, 6 districts, 1 citywide"
            />
          </div>
        </div>
        <H3 className="mt-6">Print Creative</H3>
        <P>
          This year’s volume of political mail was unprecedented. Mailboxes were stuffed with flyers
          for ballot measures and candidates. We knew that voters would be overwhelmed with printed
          ads, and it is nearly impossible to stand out while not annoying voters. So instead of
          creating glossy mailers that go straight from the mailbox to the recycling bin, we focused
          on no-frills high information mail. And based on our past research, we know our mail gets
          opened.
        </P>
        <P>
          We sent our voter guide to every voter in San Francisco twice, and did so in a plain
          envelope. While we did send a single glossy flyer, but it focused on a single issue so
          that it would be clear to voters what our candidate stood for.
        </P>
        <div className="w-full flex flex-col gap-8 justify-center lg:items-center lg:gap-16 md:flex-row">
          {/* Metrics*/}
          <div className="flex flex-col gap-6 lg:gap-8 min-w-[300px] lg:min-w-[380px]">
            <SpentNumber value="12 billboards" subtitle="Districts 1 and 5" />
            <SpentNumber value="16 issues" subtitle="Policy positions across multiple candidates" />
            <SpentNumber value="28 variants" subtitle="English and Chinese, multiple pieces" />
          </div>
          <GatsbyImage
            className="object-scale-down w-full h-full max-w-2xl"
            image={PrintCreative}
            alt="Our web and print voter guides"
          />
        </div>
        {/* Ads */}
        <H3 className="mt-6">Digital Creative</H3>
        <P>
          Voters spend a lot of time online: searching for information on candidates, deciding how
          to vote for propositions, and finding out who their friends vote for. What has also become
          clear is that voters are saturated with online content about who to vote for.
        </P>
        <P>
          We focused on providing voters with new and useful information about several
          under-the-radar races, and which candidates met their preferences. Additionally, we ran
          some of the earliest online campaigns in San Francisco with the intention of informing
          voters before the deluge began.
        </P>
        <P>
          We created all of our Meta ads in house and had 21.3 million impressions across hundreds
          of creative variants. We produced 23 candidate videos for our YouTube ads, which totaled
          9.68 million impressions.
        </P>
        <div className="flex flex-col gap-6 justify-center lg:items-center py-8 lg:flex-row lg:gap-16 lg:py-12">
          <SpentNumber
            normalCase={true}
            value="21.3 million"
            subtitle="impressions of our Meta ads"
          />
          <SpentNumber
            normalCase={true}
            value="9.68 million"
            subtitle="impressions of our video ads"
          />
        </div>
        <H4>Citywide ads</H4>
        <P>
          Our citywide video ads sought to do one thing: tell voters that GrowSF makes the ballot
          easy to understand, and that we're a helpful resource.
        </P>
        <div className="flex flex-col justify-center items-center gap-4 min-h-[490px] lg:gap-16 lg:min-h-96 lg:flex-row">
          <ShowIfPageScrolled>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=b3ynVfkXcaw"
              config={{
                youtube: {
                  playerVars: { controls: 1, showinfo: 1 },
                },
              }}
              className="rounded-md overflow-hidden isolate w-full max-w-full max-h-60 sm:w-auto sm:max-h-none"
            />
            <ReactPlayer
              url="https://www.youtube.com/watch?v=cdHybxYB03Q"
              config={{
                youtube: {
                  playerVars: { controls: 1, showinfo: 1 },
                },
              }}
              className="rounded-md overflow-hidden isolate w-full max-w-full max-h-60 sm:w-auto sm:max-h-none"
            />
          </ShowIfPageScrolled>
        </div>
        <br />
        <H4>District 3 ads</H4>
        <P>
          For Danny Sauter in District 3, we focused on quality of life improvements like more trash
          cans, public safety, and helping small businesses.
        </P>
        <div className="flex flex-wrap justify-center items-center gap-4 lg:gap-16">
          <ShowIfPageScrolled>
            <YTSmall url="https://youtu.be/9hwatGgc_7w" /> {/* Safer */}
            <YTSmall url="https://youtu.be/sbeK-1Hmb80" /> {/* Plan */}
          </ShowIfPageScrolled>
        </div>
        <br />
        <H4>District 5 ads</H4>
        <P>
          For Bilal Mahmood in District 5, we drew a distinction between the incumbents and Mahmood
          on housing and drug policy. For example, the incumbent blocked thousands of new homes as
          Supervisor, while Mahmood pledges to help homebuilders build homes. On drugs, we
          contrasted the incumbent's do-nothing policy for fentanyl dealing and overdoses, and
          Mahmood's focus on solving these issues. Finally, we highlighted the fact that the
          Tenderloin, which is the center of the City's drug overdose crisis, is home to the highest
          density of children in the entire city.
        </P>
        <div className="flex flex-wrap justify-center items-center gap-4 lg:gap-16 pb-6">
          <ShowIfPageScrolled>
            <YTSmall url="https://youtu.be/UoswSuCEcJg" /> {/* Enough */}
            <YTSmall url="https://youtu.be/rYlmD6uBDZE" /> {/* Bilal vs Dean */}
            <YTSmall url="https://youtu.be/PFLS5zKgGJg" /> {/* Drug markets */}
            <YTSmall url="https://youtu.be/ZcIk01CJo4c" /> {/* Kids */}
            <YTSmall url="https://youtu.be/ErxChzBAEvU" /> {/* Overdose */}
          </ShowIfPageScrolled>
        </div>
      </div>
    </section>
  );
};
const Lessons = () => {
  return (
    <section data-scroll="lessons-learned">
      <div className="max-w-7xl mx-auto px-5">
        <SectionTitle className="pb-0" title="Lessons Learned" />
        <P>
          Entrenched political interests mobilized roughly $2M to win districts 1 and 11, dwarfing
          the sums GrowSF spent supporting Marjan Philhour and Michael Lai. They spent $1.2M in D1
          versus our $100k, and $700k vs our $100k in D11. The speed and scale at which they can
          mobilize money against reformer Democrats surprised us.
        </P>
        <P>
          What really set us apart was our ability to do almost everything in house. We could go
          from an idea to a video launch on TV in just a couple hours. We did not farm our work out to
          expensive political consultants. We ran data-driven campaigns focused on informing as many
          voters as possible. Our speed and excellence on video is confirmed by the sheer number of
          people who say they saw our video ads in every Supervisor district.
        </P>
      </div>
    </section>
  );
};
const WhatsNext = () => {
  return (
    <section data-scroll="what’s-next">
      <div className="max-w-7xl mx-auto px-5">
        <SectionTitle className="pb-0" title="What’s Next" />
        <P>
          Now that we have a commonsense majority on the Board of Supervisors, the hard part begins.
          We will work alongside our allies inside and outside of City Hall to support positive
          change in San Francisco. We will continue to push for legislation that people want, and
          work toward structural reforms to City government that empower the Mayor to actively
          manage departments while making Supervisors more responsive to their constituents.
        </P>
        {/* CTA */}
        <div className="w-full pb-12">
          <CTA />
        </div>
      </div>
    </section>
  );
};
const FullResults = () => {
  const isMobile = useCheckMobileScreen(1024);
  const [showMore, setShowMore] = useState(false);
  return (
    <section data-scroll="results">
      <div className="max-w-7xl mx-auto px-5">
        <SectionTitle title="Full Results" className="!w-full !max-w-full" />
        <div className="flex flex-col gap-8 pb-12">
          {/* SF Candidates */}
          <div className="w-full">
            <h3 className="text-xl font-bold text-brand-blue-5 mb-3 lg:text-2xl lg:font-black">
              Mayor
            </h3>
            <div className="w-full flex flex-col gap-6 md:flex-row lg:gap-10">
              {/* Mayor */}
              <P className="max-w-sm !mx-0">
                GrowSF endorsed Daniel Lurie, London Breed, and Mark Farrell for Mayor, and
                encouraged voters to rank all three in the order that made sense for them. We're
                thrilled that <b>Daniel Lurie won</b>, and that <b>Aaron Peskin lost decisively</b>.
              </P>
              <div className="w-full max-w-sm flex flex-col">
                <Result title="Daniel Lurie" result="Elected" success={true} />
                <Result title="Aaron Peskin" result="Lost" success={true} />
              </div>
            </div>
          </div>
          <div className="w-full">
            <h3 className="text-xl font-bold text-brand-blue-5 mb-3 lg:text-2xl lg:font-black">
              Board of Supervisors
            </h3>
            <div className="w-full flex flex-col justify-between gap-6 lg:flex-row lg:gap-10">
              <div className="max-w-sm">
                <P>
                  We achieved our goal of <b>winning two seats</b> on the Board of Supervisors!
                </P>
                <P>
                  We now have a <b>majority of commonsense Supervisors</b> on the Board of
                  Supervisors.
                </P>
              </div>
              {/* Board of Supervisors */}
              <div className="w-full columns-1 sm:columns-2 gap-6">
                <Result
                  title="Marjan Philhour"
                  subtitle="Supervisor, District 1"
                  result="Lost"
                  success={false}
                  percent="48%"
                />
                <Result
                  title="Danny Sauter"
                  subtitle="Supervisor, District 3"
                  result="Elected"
                  success={true}
                  percent="55%"
                />
                <Result
                  title="Bilal Mahmood"
                  subtitle="Supervisor, District 5"
                  result="Elected"
                  success={true}
                  percent="52%"
                />
                <Result
                  title="Matt Boschetto"
                  subtitle="Supervisor, District 7"
                  result="Lost"
                  success={false}
                  percent="47%"
                />
                <Result
                  title="Trevor L. Chandler"
                  subtitle="Supervisor, District 9"
                  result="Lost"
                  success={false}
                  percent="40%"
                />
                <Result
                  title="Michael Lai"
                  subtitle="Supervisor, District 11"
                  result="Lost"
                  success={false}
                  percent="49%"
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <h3 className="text-xl font-bold text-brand-blue-5 mb-3 lg:text-2xl lg:font-black">
              Other Local Offices
            </h3>
            <div className="w-full flex flex-col justify-between gap-6 lg:flex-row lg:gap-10">
              {/* Board of Education */}
              <div className="w-full flex flex-col">
                <h4 className="text-base font-bold text-brand-blue-5">Board of Education</h4>
                <Result title="Jaime Huling" result="Elected" success={true} />
                <Result title="Parag Gupta" result="Elected" success={true} />
                <Result title="Supryia Ray" result="Elected" success={true} />
                <Result title="John Jersin" result="Lost" success={false} />
                <p className="text-sm text-gray-700 leading-relaxed pt-2 lg:text-base">
                  Fiscally responsible, pro-math leaders now hold a{' '}
                  <b>majority on the Board of Education</b>. With 5 out of 7 seats held be people
                  who will prioritize student excellence and financial stability, the future of SF
                  public schools is bright.
                </p>
              </div>
              {/* Community College Board */}
              <div className="w-full flex flex-col">
                <h4 className="text-base font-bold text-brand-blue-5">Community College Board</h4>
                <Result title="Heather McCarty" result="Elected" success={true} />
                <Result title="Aliya Chisti" result="Elected" success={true} />
                <Result title="Luis Zamora" result="Elected" success={true} />
                <Result title="Ruth Ferguson" result="Lost" success={false} />
                <p className="text-sm text-gray-700 leading-relaxed pt-2 lg:text-base">
                  We needed to win all four seats to get a commonsense majority on the City College
                  Board of Trustees, so <b>we fell short by one seat</b>. But we feel optimistic
                  that having a much closer balance of power will lead to better outcomes for
                  students.
                </p>
              </div>
            </div>
          </div>
          {/* Supervisor and others */}
          <div className="w-full">
            <div className="columns-1 sm:columns-2 md:columns-3 gap-6">
              <Result title="David Chiu" subtitle="City Attorney" result="Elected" success={true} />
              <Result
                title="Brooke Jenkins"
                subtitle="District Attorney"
                result="Elected"
                success={true}
              />
              <Result title="Paul Miyamoto" subtitle="Sheriff" result="Elected" success={true} />
              <Result title="José Cisneros" subtitle="Treasurer" result="Elected" success={true} />
              <Result
                title="Victor Flores"
                subtitle="BART Board, District 7"
                result="Elected"
                success={true}
              />
              <Result
                title="Joe Sangirardi"
                subtitle="BART Board, District 9"
                result="Lost"
                success={false}
              />
            </div>
          </div>
          {/* Show More at Mobile */}
          {(showMore || !isMobile) && (
            <Fragment>
              {/* SF Propositions */}
              <div className="w-full">
                <h3 className="text-xl font-bold text-brand-blue-5 mb-3 lg:text-2xl lg:font-black">
                  SF Propositions
                </h3>
                <div className="w-full columns-1 sm:columns-2 md:columns-3 gap-6">
                  <Result
                    yes={true}
                    title="Yes to Proposition A"
                    subtitle="Schools Improvement and Safety Bond"
                    result="Passed"
                    success={true}
                  />
                  <Result
                    yes={true}
                    title="Yes to Proposition B"
                    subtitle="Healthcare, Streets, and Shelters Bond"
                    result="Passed"
                    success={true}
                  />
                  <Result
                    yes={false}
                    title="No to Proposition C"
                    subtitle="Inspector General"
                    result="Passed"
                    success={false}
                  />
                  <Result
                    yes={true}
                    title="Yes to Proposition D"
                    subtitle="City Commission Reform"
                    result="Failed"
                    success={false}
                  />
                  <Result
                    yes={false}
                    title="No to Proposition E"
                    subtitle="Fake Commission Reform"
                    result="Passed"
                    success={false}
                  />
                  <Result
                    yes={true}
                    title="Yes to Proposition F"
                    subtitle="Deferred Retirement for SF Police"
                    result="Failed"
                    success={false}
                  />
                  <Result
                    yes={false}
                    title="No to Proposition G"
                    subtitle="Duplicate spending on housing subsidies"
                    result="Passed"
                    success={false}
                  />
                  <Result
                    yes={true}
                    title="Yes to Proposition H"
                    subtitle="Expanded retirement benefits for firefighters"
                    result="Passed"
                    success={true}
                  />
                  <Result
                    yes={true}
                    title="Yes to Proposition I"
                    subtitle="Nurses Retirement Credit"
                    result="Passed"
                    success={true}
                  />
                  <Result
                    yes={true}
                    title="Yes to Proposition J"
                    subtitle="Student Success Fund accountability"
                    result="Passed"
                    success={true}
                  />
                  <Result
                    yes={true}
                    title="Yes to Proposition K"
                    subtitle="Permanently close part of Great Highway"
                    result="Passed"
                    success={true}
                  />
                  <Result
                    yes={false}
                    title="No to Proposition L"
                    subtitle="Tax riders of Uber, Lyft, and Waymo"
                    result="Failed"
                    success={true}
                  />
                  <Result
                    yes={true}
                    title="Yes to Proposition M"
                    subtitle="Business Tax Reform"
                    result="Passed"
                    success={true}
                  />
                  <Result
                    yes={true}
                    title="Yes to Proposition N"
                    subtitle="First responder student loan forgiveness program"
                    result="Passed"
                    success={true}
                  />
                  <Result
                    yes={true}
                    title="Yes to Proposition O"
                    subtitle="Reproductive Freedom"
                    result="Passed"
                    success={true}
                  />
                </div>
              </div>
              {/* State Candidates */}
              <div className="w-full">
                <h3 className="text-xl font-bold text-brand-blue-5 mb-3 lg:text-2xl lg:font-black">
                  State Candidates
                </h3>
                <div className="columns-1 sm:columns-2 md:columns-3 gap-6">
                  <Result
                    title="Scott Wiener"
                    subtitle="State Senate, District 11"
                    result="Elected"
                    success={true}
                  />
                  <Result
                    title="Matt Haney"
                    subtitle="State Assembly, District 17"
                    result="Elected"
                    success={true}
                  />
                  <Result
                    title="Catherine Stefani"
                    subtitle="State Assembly, District 19"
                    result="Elected"
                    success={true}
                  />
                </div>
              </div>
            </Fragment>
          )}
          <button
            data-expand="true"
            className="flex justify-center items-center w-full px-5 py-3.5 mx-auto text-xs font-bold rounded-md uppercase tracking-widest bg-pos-0 scale-100 border border-brand-blue-4 text-brand-blue-4 bg-transparent transition duration-200 hover:bg-gradient-to-r hover:from-brand-blue-4 hover:via-brand-blue-4 hover:to-brand-green-3 hover:bg-size-200 hover:border-transparent hover:bg-pos-100 hover:text-white hover:scale-105 focus:bg-brand-blue-5 focus:border-white focus:text-white focus:bg-none focus:scale-100 sm:w-max lg:hidden"
            onClick={() => {
              setShowMore(!showMore);
              showMore &&
                setTimeout(
                  () =>
                    window?.scrollTo({
                      behavior: 'instant',
                      top:
                        document?.querySelector('[data-expand=true]')?.getBoundingClientRect().top +
                        window?.scrollY,
                    }),
                  0
                );
            }}
          >
            {showMore ? 'Show fewer results' : 'Show full results'}
          </button>
        </div>
      </div>
    </section>
  );
};

const Metrics = () => {
  const tableHeaders = [
    {
      Header: 'Channel',
      accessor: 'channel',
    },
    {
      Header: 'Reach',
      accessor: 'reach',
    },
  ];
  const tableContentData = [
    {
      channel: '**Website voter guide**',
      reach: '**384k** impressions across **181k** uniques',
    },
    {
      channel: '**Direct mail**',
      reach: '**2-5 pieces** to **514k+ voters**',
    },
    {
      channel: '**YouTube ads**',
      reach: '**9.68M** impressions',
    },
    {
      channel: '**Connected TV ads**',
      reach: '**1.54M** impressions',
    },
    {
      channel: '**Meta ads**',
      reach: '**21.3M** impressions',
    },
  ];
  const tableContent = useMemo(
    () =>
      tableContentData.map((data) => {
        return {
          channel: (
            <ReactMarkdown className="[&_strong]:font-bold [&_p]:text-base [&_p]:text-brand-gray-5 py-3">
              {data.channel}
            </ReactMarkdown>
          ),
          reach: (
            <ReactMarkdown className="[&_strong]:font-bold [&_p]:text-base [&_p]:text-brand-gray-4 py-3">
              {data.reach}
            </ReactMarkdown>
          ),
        };
      }),
    [tableContentData]
  );

  const SpendingPieChart = () => {
    // $1.8M total
    // 1.1M voter guide
    // 685k Supes
    // 15k other
    const data = [
      { name: 'Voter Guide', value: 1100000 },
      { name: 'Supervisors', value: 685000 },
      { name: 'Other', value: 15000 },
    ];

    const COLORS = ['#1271A7', '#4BADE4', '#1E7B6A'];
    // Dollar amount formatter using Intl.NumberFormat
    const abbreviateNumber = (value) => {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 1, // Show up to one decimal place
      });

      if (value >= 1_000_000) {
        const formatted = value / 1_000_000;
        return formatted % 1 === 0
          ? `${formatter.format(formatted).replace('.0', '')}M`
          : `${formatter.format(formatted)}M`;
      } else if (value >= 1_000) {
        const formatted = value / 1_000;
        return formatted % 1 === 0
          ? `${formatter.format(formatted).replace('.0', '')}k`
          : `${formatter.format(formatted)}k`;
      } else {
        return formatter.format(value); // No abbreviation
      }
    };
    return (
      <PieChart width={600} height={400} className="max-w-full sm:max-w-md">
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius="70%"
          fill="#8884d8"
          dataKey="value"
          label={(entry) => `${entry.name}: ${abbreviateNumber(entry.value)}`}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    );
  };
  return (
    <section data-scroll="metrics">
      <div className="max-w-7xl mx-auto px-5">
        <SectionTitle className="pb-0" title="Metrics" />
        <P>
          GrowSF reached every voter in San Francisco. From postal mail, to digital ads, to TV and
          YouTube, we made sure that every voter knew where we stood. 181,000 voters came to our
          website, which comprises just over 44% of all voters in this election. When combined with
          our postal mail and video outreach, we think every voter was aware of GrowSF and our
          endorsed candidates.
        </P>
        <P>
          And we did all of this at a <i>fraction</i> of what the other guys spend. Our total budget for this
          election was $1.8M. Of that, $1.1M was spent promoting our complete voter guide and $685k was spent
          on ads for our Supervisor candidates. 
        </P>
        <div className="flex flex-col gap-8 pb-12">
          <div className="w-full flex flex-col gap-2 lg:items-center lg:gap-32 lg:flex-row lg:justify-between">
            <SpendingPieChart />
            {/* Table */}
            <div className="w-full lg:w-3/5">
              <Table tableData={tableContent} columnData={tableHeaders} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Impact = ({ location, data }) => {
  const hero = getImage(data.hero.childImageSharp);
  const IVotedV = getImage(data.IVotedV.childImageSharp);
  const IVotedH = getImage(data.IVotedH.childImageSharp);
  const IHeartSF = getImage(data.IHeartSF.childImageSharp);
  const Astranis = getImage(data.Astranis.childImageSharp);
  const innerNavData = [
    {
      text: 'Intro',
      scrollTo: 'intro',
    },
    {
      text: 'A New Majority',
      scrollTo: 'majority',
    },
    {
      text: 'Metrics',
      scrollTo: 'metrics',
    },
    {
      text: 'How We Did It',
      scrollTo: 'how-we-did-it',
      active: false,
    },
    {
      text: 'Lessons Learned',
      scrollTo: 'lessons-learned',
    },
    {
      text: 'What’s Next',
      scrollTo: 'what’s-next',
    },
    {
      text: 'Full Results',
      scrollTo: 'results',
    },
  ];

  const [activeInnerNav, setActiveInnerNav] = useState('');
  return (
    <Layout>
      <SEO
        title="GrowSF's impact on the November 2024 election"
        description="Here's how GrowSF helped moderates win a majority in San Francisco."
        pathname={location.pathname}
        image={data?.opengraph?.childImageSharp.resize || null}
      />
      {/* Start Hero */}
      <section>
        <div className="relative flex flex-col justify-end overflow-hidden -mt-px h-[500px]">
          <div className="absolute inset-0">
            <div className="absolute z-10 top-0 left-0 w-full h-full pointer-events-none [background:linear-gradient(0deg,_rgba(23,_40,_65,_0.50)_0%,_rgba(23,_40,_65,_0.00)_100%),_linear-gradient(253deg,_rgba(254,_220,_107,_0.50)_-39.44%,_rgba(254,_220,_107,_0.00)_66.87%),_linear-gradient(0deg,_#4BADE4_0%,_#4BADE4_100%),_linear-gradient(0deg,_#FF9B86_0%,_#FF9B86_100%),_linear-gradient(0deg,_#000_0%,_#000_100%)] opacity-75 [background-blend-mode:normal,normal,multiply,screen,saturation]"></div>
            <GatsbyImage
              image={hero}
              loading="eager"
              alt="A view of San Francisco from Dolores Park"
              className="h-full z-0 min-h-[250px]"
            />
          </div>
          <div className="relative w-full max-w-7xl mx-auto flex px-5 py-8 lg:py-16">
            <div className="z-10 relative text-left">
              <h1 className="text-5xl font-black table-caption text-white capitalize mb-2.5 lg:block lg:text-7xl">
                November 2024 Impact Report
              </h1>
              <p className="text-xl font-bold text-white capitalize lg:text-3xl lg:font-black">
                How common sense won
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero */}
      {/* start Inner Nav */}
      <section className="sticky top-0 z-50">
        <InnerNav
          innerNavData={innerNavData}
          activeInnerNav={activeInnerNav}
          setActiveInnerNav={setActiveInnerNav}
        />
      </section>
      {/* End Inner Nav */}
      {/* Start Intro*/}
      <section data-scroll="intro">
        <div className="w-full overflow-hidden">
          <div className="max-w-7xl mx-auto px-5 flex flex-col-reverse gap-0 lg:gap-10 xl:flex-row items-center">
            <div className="w-full max-w-xl font-light my-auto py-8 sm:py-10">
              <P>
                We started GrowSF with a singular focus: win a{' '}
                <A href="https://growsf.org/blog/2024-06-building-toward-november/#what-s-next">
                  commonsense majority
                </A>{' '}
                on the Board of Supervisors. The November 2024 election was our first shot at
                winning that majority, and we’re proud to say we did it!
              </P>
              <P>
                San Francisco now has a Board of Supervisors, Mayor, and Board of Education that
                will work together for the common good.
              </P>
              <P>
                Our strategy for November 2024 was the same as it’s always been: focus on policy,
                take the concerns of voters seriously, and put out the best, most deeply researched
                voter guide. For Mayor, we encouraged voters to rank Daniel Lurie, London Breed, and
                Mark Farrell. We compared the candidates along several metrics: Independence,
                Building More Homes, Public Safety, Addiction & Fentanyl Dealing, Homelessness,
                Public Transit & Bikes, and Small Business.
              </P>
              <P>
                For Supervisor, we endorsed the supervisor candidates with the right policy plans.
                In District 5, we endorsed Bilal Mahmood for his focus on ending the fentanyl
                markets and building more homes. In District 3, Danny Sauter had sensible plans for
                installing more trash cans, helping small businesses by rolling back red tape, and
                building more homes.
              </P>
            </div>
            <div className="flex items-center -mx-5 xl:mx-0 xl:mr-0 xl:translate-x-0">
              <div className="flex flex-col gap-2 lg:gap-5">
                <div className="w-fit flex items-end gap-2 lg:gap-5">
                  <div className="max-w-[529px] max-h-[291px]">
                    <GatsbyImage
                      className="object-contain w-full h-full"
                      image={IVotedH}
                      alt="I Voted sticker on an American Flag, on top of a vote-by-mail ballot"
                    />
                  </div>
                  <div className="max-w-[218px] max-h-[290px]">
                    <GatsbyImage
                      className="object-contain w-full h-full"
                      image={IVotedV}
                      alt="I voted stickers on a long roll"
                    />
                  </div>
                </div>
                <div className="w-fit flex items-start gap-2 lg:gap-5">
                  <div className="max-w-[218px] max-h-[290px]">
                    <GatsbyImage
                      className="object-cover w-full h-full"
                      image={IHeartSF}
                      alt="I <3 SF by GrowSF in the window of a Painted Lady"
                    />
                  </div>
                  <div className="max-w-[529px] max-h-[291px]">
                    <GatsbyImage
                      className="object-cover w-full h-full"
                      image={Astranis}
                      alt="The GrowSF team at Astranis Space"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Intro*/}
      <Majority />
      <DividerPattern />
      <Metrics />
      <DividerPattern />
      <HowWeDidIt data={data} />
      <DividerPattern />
      <Lessons />
      <DividerPattern />
      <WhatsNext />
      <DividerPattern />
      <FullResults />
    </Layout>
  );
};
export default Impact;

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "impact-report/hero-impact.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FULL_WIDTH
          formats: [WEBP, AUTO]
          breakpoints: [400, 800, 1200, 1400]
        )
      }
    }
    IVotedV: file(relativePath: { eq: "impact-report/i-voted-v.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    IVotedH: file(relativePath: { eq: "impact-report/i-voted-h.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }

    IHeartSF: file(relativePath: { eq: "impact-report/i-heart-sf-growsf.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    Astranis: file(relativePath: { eq: "impact-report/growsf-astranis.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    VGPrintCreative: file(relativePath: { eq: "impact-report/vg-print-creative-nov-2024.png" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    PrintCreative: file(relativePath: { eq: "impact-report/print-creative-nov-2024.png" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    opengraph: file(relativePath: { eq: "impact-report/november-2024-impact-report.png" }) {
      id
      childImageSharp {
        gatsbyImageData
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
  }
`;
