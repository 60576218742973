import React, { useState, useRef, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

const InnerNav = ({ innerNavData, activeInnerNav, setActiveInnerNav }) => {
  // get height of the header to offset the scroll
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (ref.current) {
      const { height } = ref.current.getBoundingClientRect();
      setHeight(height);
    }
  }, []);
  return (
    <div ref={ref} className="w-full max-w-full overflow-x-auto [&::-webkit-scrollbar]:!h-0">
      <div className="min-w-max px-5 h-16 bg-brand-blue-5 flex justify-center items-center gap-8 lg:gap-16">
        {innerNavData?.map((item, index) => (
          <button
            key={'nav' + index}
            className={twMerge(
              'text-xs font-bold uppercase whitespace-nowrap min-w-max tracking-widest lg:text-sm',
              activeInnerNav === item.text ? 'text-brand-blue-3' : 'text-white'
            )}
            onClick={() => {
              setActiveInnerNav(item.text);
              window?.scrollTo({
                behavior: 'smooth',
                top:
                  document
                    ?.querySelector(
                      `[data-scroll=${item.scrollTo.toLowerCase().replaceAll(' ', '-')}]`
                    )
                    ?.getBoundingClientRect().top +
                  window?.scrollY -
                  height,
              });
            }}
          >
            {item.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default InnerNav;
