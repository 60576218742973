import React from 'react';
import { twMerge } from 'tailwind-merge';

const List = ({
  title,
  list,
  type = 'disc',
  textColor = '',
  className = '',
  listClassName = '',
}) => {
  return (
    <div className="flex flex-col gap-4">
      {title && (
        <span
          className={`text-left text-xs uppercase font-bold ${
            textColor ? textColor : 'text-gray-700'
          }`}
        >
          {title}
        </span>
      )}
      <div
        className={twMerge(
          'flex flex-col gap-1 pl-5',
          type === 'disc' ? 'list-disc' : 'list-decimal',
          listClassName
        )}
      >
        {list?.map((item, index) => (
          <div
            key={`list_${index}`}
            className={twMerge(
              'text-left text-sm font-light list-item',
              textColor ? textColor : 'text-gray-700',
              className
            )}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
export default List;
