import React from 'react';
import { twMerge } from 'tailwind-merge';

const Question = ({ children, smallText, className = '' }) => {
  return (
    <div
      className={twMerge(
        'flex flex-col',
        smallText ? 'gap-1.5 w-full' : 'gap-3 sm:gap-5',
        className
      )}
    >
      {children}
    </div>
  );
};

export default Question;
